<template>
  <div class="detail">
    <navbar
      :title="`${detail.title || '加载中'}（${detail.juanming || '加载中'}）`"
    >
      <template #right>
        <img
          class="menu-img"
          src="@/assets/h5images/detail/menu.png"
          alt=""
          @click="showMenu = true"
        />
      </template>
    </navbar>
    <div v-loading="loading.detail" class="detail-inner">
      <div class="detail-main">
        <div class="detail-browser">
          <div v-loading="loading.pdf" class="browser-inner">
            <img class="pdf-image" :src="currImg" alt="" />
            <img
              class="icon-left"
              src="@/assets/images/detail/img_64679_0_5.png"
              @click="handleTurnPage('prev')"
            />
            <img
              class="icon-right"
              src="@/assets/images/detail/img_64679_0_6.png"
              @click="handleTurnPage('next')"
            />
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-loading="loading.cata"
      v-model="showMenu"
      position="bottom"
      class="menu-popup"
    >
      <img
        class="close-icon"
        src="@/assets/h5images/detail/close.png"
        alt=""
        @click="showMenu = false"
      />
      <div class="title">{{ detail.title }}</div>
      <div class="sub-title">{{ detail.timing || "xx房" }}</div>
      <div class="menu-content">
        <div class="menu-title">目录</div>
        <div
          v-for="item in cata"
          :key="item.id"
          class="menu-item"
          @click="navigateToDetail(item.id)"
        >
          {{ item.title }}（{{ item.juanming }}）
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Popup } from "vant";
import materialApis from "@/apis/material";
import Navbar from "@/components/h5/Navbar";

export default {
  name: "DetailPreview",
  components: {
    Navbar,
    "van-popup": Popup
  },
  props: {
    propDetail: null
  },
  data() {
    return {
      loading: {
        detail: false,
        cata: false,
        pdf: false
      },
      showMenu: false,
      detail: {},
      cata: [],
      pdfPage: 1,
      pdfNomore: false,
      pdfTotalPage: 10000,
      currPage: 0,
      pdfImages: [],
      needBuy: false
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    currImg() {
      return this.pdfImages[this.currPage];
    }
  },
  async mounted() {
    if (this.propDetail) {
      this.detail = this.propDetail;
    } else {
      this.fetchDetail();
    }
    // this.fetchPdfs(4);
    this.unlisten = this.initEvents();
  },
  beforeDestroy() {
    this.unlisten && this.unlisten();
  },
  methods: {
    initEvents() {
      const loginListener = () => {};

      this.bus.on("login-success", loginListener);

      return () => {
        this.bus.off("login-success", loginListener);
      };
    },

    showPayDialog() {
      if (this.userInfo.last_count > 0) {
        // 是vip 使用vip次数
        this.$refs["tips"].show();
      } else {
        // 购买
        this.$refs["pay-dialog"].show();
      }
    },

    async fetchDetail() {
      this.loading.detail = true;
      try {
        const id = this.$route.query.id || this.$route.params.id;
        const {
          data: { material_info, isbuy, isduihuan }
        } = await materialApis.myMaterialDetail({ id });
        this.detail = { ...material_info, isbuy, isduihuan };
        if (parseFloat(material_info.price) > 0 && !this.userInfo.isLogin) {
          this.$router.replace("/h5/login");
        }
      } catch (error) {
        console.error(error);
      }
      this.loading.detail = false;
    },

    async fetchCata({ sn }) {
      this.loading.cata = true;
      const {
        data: { material_list }
      } = await materialApis.menuList({ sn });
      this.cata = material_list;
      this.loading.cata = false;
    },

    handleTurnPage(type) {
      if (!this.pdfImages[this.currPage]) return;
      if (type === "next") {
        if (!this.pdfImages[this.currPage + 1]) return;
        this.currPage += 1;
      } else {
        if (!this.pdfImages[this.currPage - 1]) return;
        this.currPage -= 1;
      }

      if (this.pdfImages.length - this.currPage <= 4) {
        this.fetchPdfs();
      }
    },

    async fetchPdfs(page = 1) {
      // if (!this.userInfo.id && this.detail.price > 0) return; // 价格大于0 且未登陆 不请求pdf
      if (this.pdfPage > 1 && this.pdfPage > this.pdfTotalPage) return; // 超过最大数量
      // if (this.detail.price > 0) {
      //   // 超过最大可读数量
      //   this.showPayDialog();
      //   return;
      // }

      this.loading.browser = true;
      while (page > 0) {
        await materialApis
          .materialDetailPdf({
            id: this.$route.query.id || this.$route.params.id,
            page: this.pdfPage
          })
          .then(({ data: { pdf_path: pdfImage, totalpage } }) => {
            this.pdfImages.push(pdfImage);
            this.pdfTotalPage = totalpage;
            this.pdfPage += 1;
            // this.$refs["flip"]?.updatePage();
          })
          .catch(() => {
            page = 0;
          });
        page -= 1;
      }
      this.loading.browser = false;
    },

    navigateToDetail(id) {
      if (
        id == (this.$route.query.id || this.$route.params.id) ||
        this.loading.detail
      )
        return;

      this.showMenu = false;
      this.$nextTick(() => {
        this.$router.replace({
          path: "/h5/search/detail",
          query: { id }
        });
      });

      // this.fetchDetail();
    },

    toggleHideMenu() {
      this.hideMenu = !this.hideMenu;
    },

    confirmNext() {
      this.$refs["tips"].hide();
    },

    refreshPdf() {
      this.pdfPage = 1;
      this.currPage = 0;
      this.pdfImages = [];
    },

    handleDetailChange(detail) {
      // 获取完谱书详情，根据谱书是否收费，判断是否需要弹出登陆窗
      if (!detail.id) return;
      this.refreshPdf();
      if (!this.userInfo.isLogin) {
        // 未登陆
        // this.bus.emit("login");
        this.$router.replace("/h5/login");
      } else {
        // 已登陆 显示购买或者消耗次数弹窗
        console.log("fetch");
        this.fetchPdfs(4);
        // this.showPayDialog();
      }
    }
  },

  watch: {
    detail: {
      immediate: true,
      handler(val, oldVal) {
        if (val?.sn && val?.sn !== oldVal?.sn) {
          this.fetchCata({ sn: val.sn });
        }
        this.handleDetailChange(val);
      }
    },
    $route(val, oldVal) {
      if (val.query.id !== oldVal.query.id) {
        this.fetchDetail();
      }
    }
  }
};
</script>

<style lang="scss">
.nav-bar {
  &::after {
    border: none;
  }
}
.nav-title {
  font-size: 60px;
}
.menu-img {
  height: 40px;
  width: 55px;
}
.detail {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden;
  // background: linear-gradient(0deg, #fbe2c4 0%, #faf3ea 100%);
  // height: calc(100vh - 70px);

  .detail-inner {
    // max-width: 1200px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    background: linear-gradient(0deg, #fbe2c4 0%, #faf3ea 100%);
  }

  .charge-tips {
    box-sizing: border-box;
    display: flex;
    position: relative;
    align-items: center;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    border-radius: 4px;
    background-color: #f7d1a3;
    padding: 0 40px;
    box-sizing: border-box;
    width: 1045px;
    height: 100px;
    overflow: hidden;
    flex: none;

    .content {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 100px;

      .tip-text {
        margin-right: 43px;
        // height: 15px;
        font-size: 34px;
        color: #CA0A15;
        line-height: 100px;
        webkit-line-clamp: 2px;
        overflow: hidden;
        text-decoration: none;
        text-overflow: ellipsis;
        letter-spacing: 0px;
        white-space: nowrap;
      }

      .buy-btn {
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        width: 120px;
        height: 60px;
        background: linear-gradient(-90deg, #ee6a0c 0%, #fd9e3b 100%);
        border-radius: 30px;

        .text {
          position: relative;
          // max-width: 39px;
          overflow: hidden;
          text-decoration: none;
          text-overflow: ellipsis;
          letter-spacing: 0px;
          white-space: nowrap;
          color: #ffffff;
          font-size: 30px;
        }
      }
    }
  }

  .detail-main {
    display: flex;
    position: relative;
    align-items: center;
    align-self: center;
    flex-direction: row;
    // margin-top: 10px;
    // margin-bottom: 20px;
    // padding-top: 10px;
    // padding-bottom: 20px;
    width: 1045px;
    flex: 1;
    box-sizing: border-box;

    &.hide-menu {
      flex-flow: column nowrap;
      height: 100%;

      .left-catalogue {
        width: 100%;
        height: auto;
        margin-right: 0;

        .scale-title {
          width: 100%;
        }
      }

      .detail-browser {
        width: 100%;
      }
    }
  }

  .detail-browser {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border-radius: 4px;
    background-color: #ffffff;
    // width: 980px;
    height: 100%;
    overflow: hidden;
    flex: 1;
    // padding: 20px;
    box-sizing: border-box;
    transition: width 0.3s ease;

    .pdf-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    .browser-inner {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-radius: 4px;
      // background-color: #CA0A15;
      width: 100%;
      height: 100%;
      overflow: hidden;
      // margin-left: 5px;

      .icon-right {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 140px;
        height: 140px;
        overflow: hidden;
        cursor: pointer;
        background-color: #fff;
      }

      .icon-left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 140px;
        height: 140px;
        overflow: hidden;
        cursor: pointer;
        background-color: #fff;
      }
    }
  }
}

.tips-title {
  margin-top: 69px;
  font-size: 56px;
  color: #ff5500;
}

.tips-desc {
  margin-top: 39px;
  font-size: 40px;
  color: #666666;

  .remain {
    color: #ff5500;
  }
}

.confirm-next-btn {
  margin-top: 80px;
  height: 120px;
  width: 965px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #CA0A15;
  color: #ffffff;
  font-size: 56px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}

.menu-popup {
  height: 70vh;
  width: 100%;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0px 0px;
  padding: 62px 40px 0 40px;
  box-sizing: border-box;
  // position: relative;

  .close-icon {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50px;
    right: 40px;
  }

  .title {
    font-size: 56px;
    color: #333;
    margin-top: 62px;
  }

  .sub-title {
    font-size: 40px;
    color: #333;
    margin-top: 28px;
  }

  .menu-content {
    margin-top: 40px;
    width: 100%;
    height: calc(100% - 230px);
    background: #f7f7f7;
    border-radius: 20px 20px 0px 0px;
    overflow: auto;
    padding: 45px;
    box-sizing: border-box;

    .menu-title {
      font-size: 56px;
      color: #333;
    }

    .menu-item {
      margin-top: 30px;
      font-size: 44px;
      color: #8c4134;
    }
  }
}
</style>
